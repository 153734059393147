import Layout from '@/layout'

export default {
  path: '/customer-crm',
  component: Layout,
  meta: {
    title: '客户CRM',
    icon: 'orginazation',
    auth: ['departmentManage', 'doctorNav', 'courseNav', 'trainingNav']
  },
  children: [{
    path: '/orginazation',
    redirect: '/orginazation/list',
    name: 'Orginazation',
    meta: {
      sidebar: true,
      title: '组织管理',
      breadcrumb: false,
      cache: ['OrginazationManage', 'StaffDetail'],
      auth: ['departmentManage']
    }
  }, {
    path: '/orginazation/list',
    name: 'OrginazationList',
    component: () => import(/* webpackChunkName: "Orginazation" */ '@/views/orginazation/Orginazation'),
    meta: {
      sidebar: false,
      requiresAuth: true,
      title: '组织管理',
      breadcrumb: false,
      activeMenu: '/orginazation',
      cache: ['OrginazationManage', 'StaffDetail'],
      auth: ['departmentManage']
    }
  },
  {
    path: '/orginazation/manage',
    name: 'OrginazationManage',
    component: () => import(/* webpackChunkName: "Orginazation" */ '@/views/orginazation/OrginazationManage'),
    meta: {
      requiresAuth: true,
      title: '组织架构',
      sidebar: false,
      activeMenu: '/orginazation',
      auth: ['departmentManage']
    }
  },
  {
    path: '/orginazation/staff/:id',
    name: 'StaffDetail',
    component: () => import(/* webpackChunkName: "Orginazation" */ '@/views/orginazation/StaffDetail'),
    meta: {
      requiresAuth: true,
      title: '员工详情',
      sidebar: false,
      activeMenu: '/orginazation',
      auth: ['departmentManage']
    }
  },
  // ===== 客户管理 =====
  {
    path: '/doctor',
    redirect: '/doctor/list',
    meta: {
      title: '客户管理',
      auth: ['doctorNav']
    }
  }, {
    path: '/import/doctor',
    name: 'ImportDoctorList',
    component: () => import(/* webpackChunkName: "Orginazation" */ '@/views/doctor/ImportDoctorList'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      breadcrumb: false,
      title: '外部客户管理'
    }
  },
  {
    path: '/doctor/list',
    name: 'Doctor',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/Doctor'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      breadcrumb: false,
      title: '客户管理',
      auth: ['doctorNav'],
      cache: ['DoctorStat', 'TagCoverDoctor', 'RatingEditOrCheck', 'GradingList', 'DoctorRatingEdit']
    }
  }, {
    // 编辑创建查看分级规则
    path: '/doctor/rating-edit-or-check',
    name: 'RatingEditOrCheck',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/RatingEditOrCheck'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      breadcrumb: false,
      title: '客户分级详情',
      auth: ['doctorNav']
    }
  }, {
    // 查看待更新分级
    path: '/doctor/grading-list',
    name: 'GradingList',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/GradingList.vue'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      breadcrumb: false,
      title: '待更新分级',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/doctor-rating-edit',
    name: 'DoctorRatingEdit',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorRatingEdit.vue'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      breadcrumb: false,
      title: '编辑分级',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/tag/:id',
    name: 'TagCoverDoctor',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorTagCoverList'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '医生管理',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/stat/:id',
    name: 'DoctorStat',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorStat'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '医生详情',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/stat/meeting/:id',
    name: 'DoctorMeetingExam',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorMeetingExam'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '答题详情',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/stat/live/:id',
    name: 'DoctorLiveExam',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorLiveExam'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '医生数据',
      auth: ['doctorNav']
    }
  },
  {
    path: '/doctor/stat/webinar/:id',
    name: 'DoctorWebinarExam',
    component: () => import(/* webpackChunkName: "Doctor" */ '@/views/doctor/DoctorWebinarExam'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '医生数据',
      auth: ['doctorNav']
    }
  }, {
    path: '/doctor/stat/questionnaire/:id',
    name: 'DoctorQuestionnaireExam',
    component: () => import(/* webpackChunkName: "Doctor" */'@/views/doctor/DoctorQuestionnaireExam'),
    meta: {
      sidebar: false,
      activeMenu: '/doctor',
      requiresAuth: true,
      title: '问卷调研',
      auth: ['doctorNav']
    }
  },
  // ===== 代表培训 =====
  {
    path: '/course/management',
    name: 'CourseManagement',
    component: () => import(/* webpackChunkName: "CourseManagement" */ '@/views/trainCourse/CourseManagement'),
    meta: {
      requiresAuth: true,
      title: '课程管理',
      sidebar: true,
      auth: ['courseNav'],
      cache: ['CreateCourse', 'LookCourseData']
    }
  },
  {
    path: '/create/course',
    name: 'CreateCourse',
    component: () => import(/* webpackChunkName: "CourseManagement" */ '@/views/trainCourse/CreateCourse'),
    meta: {
      requiresAuth: true,
      title: '创建课程',
      activeMenu: '/course/management',
      sidebar: false,
      auth: ['courseNav']
    }
  },
  {
    path: '/edit/course/:courseId',
    name: 'EditCourse',
    component: () => import(/* webpackChunkName: "CourseManagement" */ '@/views/trainCourse/CreateCourse'),
    meta: {
      requiresAuth: true,
      title: '编辑课程',
      activeMenu: '/course/management',
      sidebar: false,
      auth: ['courseNav']
    }
  },
  {
    path: '/look/course/data/:courseId',
    name: 'LookCourseData',
    component: () => import(/* webpackChunkName: "CourseManagement" */ '@/views/trainCourse/LookCourseData'),
    meta: {
      requiresAuth: true,
      title: '课程详情',
      activeMenu: '/course/management',
      sidebar: false,
      auth: ['courseNav']
    }
  },
  {
    path: '/train/programs',
    name: 'TrainPrograms',
    component: () => import(/* webpackChunkName: "TrainPrograms" */'@/views/trainCourse/TrainPrograms'),
    meta: {
      requiresAuth: true,
      title: '培训管理',
      sidebar: true,
      auth: ['trainingNav'],
      cache: ['LookTrainData', 'CreateTrain']
    }
  },
  {
    path: '/create/train',
    name: 'CreateTrain',
    component: () => import(/* webpackChunkName: "TrainPrograms" */'@/views/trainCourse/CreateTrain'),
    meta: {
      requiresAuth: true,
      title: '创建培训',
      sidebar: false,
      auth: ['trainingNav']
    }
  },
  {
    path: '/edit/train/:trainId',
    name: 'EditTrain',
    component: () => import(/* webpackChunkName: "TrainPrograms" */'@/views/trainCourse/CreateTrain'),
    meta: {
      requiresAuth: true,
      title: '编辑活动',
      sidebar: false,
      auth: ['trainingNav']
    }
  },
  {
    path: '/add/train/member/:trainId',
    name: 'AddTrainMember',
    component: () => import(/* webpackChunkName: "TrainPrograms" */ '@/views/trainCourse/AddTrainMember'),
    meta: {
      requiresAuth: true,
      title: '添加培训对象',
      sidebar: false,
      activeMenu: '/train/programs',
      auth: ['trainingNav']
    }
  },
  {
    path: '/look/train/data/:trainId',
    name: 'LookTrainData',
    component: () => import(/* webpackChunkName: "TrainPrograms" */ '@/views/trainCourse/LookTrainData'),
    meta: {
      requiresAuth: true,
      title: '查看数据',
      sidebar: false,
      activeMenu: '/train/programs',
      auth: ['trainingNav']
    }
  }]
}

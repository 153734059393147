import Layout from '@/layout'
import MicroApp from '@/layout/views/micro-app.vue'

export default [
  {
    path: '/sub-app/common-page/ai-parse',
    component: MicroApp,
    meta: {
      title: 'micro-app',
      icon: 'micro-app',
      sidebar: false
    }
  },
  {
    path: '/sub-app/academic-saas/data/analysis/:id',
    name: 'DataAnalysis',
    component: MicroApp,
    meta: {
      title: '数据分析',
      sidebar: false
    }
  },
  {
    path: '/sub-app',
    redirect: '/sub-app/404',
    component: Layout,
    meta: {
      title: 'micro-app',
      icon: 'micro-app',
      sidebar: false
    },
    children: [
      {
        path: 'customer-crm/permission-management',
        name: 'PermissionManagement',
        meta: {
          activeMenu: '/orginazation',
          title: '权限管理'
        }
      },
      {
        path: 'academic-saas/podcast/create',
        name: 'CreatePodcast',
        meta: {
          title: '创建医学播客',
          activeMenu: '/academic'
        }
      },
      {
        path: 'academic-saas/pure/survey',
        name: 'CreatePureSurvey',
        meta: {
          title: '调研创建',
          activeMenu: '/academic'
        }
      },
      {
        path: 'academic-saas/podcast/detail/:id',
        name: 'PodcastDetail',
        meta: {
          title: '医学播客详情',
          activeMenu: '/academic'
        }
      },
      {
        path: 'academic-saas/meeting/create',
        name: 'CreateMeeting',
        meta: {
          title: '科会创建',
          activeMenu: '/academic'
        }
      },
      {
        path: 'academic-saas/meeting/edit/:id',
        name: 'EditMeeting',
        meta: {
          title: '科会编辑',
          activeMenu: '/academic'
        }
      },
      {
        path: 'delegation-saas/delegation/detail/:id',
        name: 'DelegationDetail',
        meta: {
          title: '委托服务详情',
          activeMenu: '/entrust',
          auth: ['entrustNav']
        }
      },
      {
        path: 'academic-saas/questionnaire/detail/:id',
        name: 'V3QuestionnaireDetail',
        meta: {
          title: '调研详情',
          activeMenu: '/academic'
        }
      },
      {
        path: '*',
        name: 'demo',
        meta: {
          title: 'demo',
          sidebar: false,
          activeMenu: '/sub-app'
        }
      }
    ].map(item => {
      item.component = MicroApp
      return item
    })
  }
]

import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

import { MessageDialog } from '@/components/MessageDialog/index.js'

// import BASE_CONFIG from 'BASE_CONFIG'

export const toLogin = () => {
  store.dispatch('logout', 'request -> login')
  router.push({
    path: '/user/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

// 创建axios实例
const service = axios.create({
  // baseURL: BASE_CONFIG.BASE_URL, // api 的 base_url
  baseURL: '/', // api 的 base_url
  timeout: 30000 // 请求超时时间
})

let messageOpen = false
// request拦截器
service.interceptors.request.use(
  config => {
    // 判断多账号切换时 token 更新不及时问题
    const { token = '' } = store.state.user?.metadataUserInfo?.orgLoginVo || {}
    if (token && getToken() && token !== getToken() && router.currentRoute.name !== 'UserLogin') {
      if (!messageOpen) {
        // 避免多次弹窗
        messageOpen = true
        MessageDialog({
          title: '警告',
          cancelText: '',
          message: '您的账号在别处登录，需要刷新后同步数据！'
        }).then(() => {
          location.reload()
          messageOpen = false
        })
      }
      return false
    }

    config['Content-Type'] = 'application/json'
    config.headers['token'] = config.headers['token'] || getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['Cache-Control'] = 'no-cache'
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 7400) {
      const url = response.config.url.replace(process.env.VUE_APP_GATEWAY_QXS, '')
      console.log(url, res.message)
      if (process.env.NODE_ENV !== 'production') {
        Message.error(`${url}:${res.message}`)
      }
    }
    const { code, message } = res
    if (code === 0) {
      return res
    } else if (code === -106) {
      toLogin()
    } else if (code == 401) {
      Message({
        message: '登录失效，请重新进入',
        type: 'error',
        duration: 5 * 1000
      })
      toLogin()
      return
    } else {    // todo 异常处理逻辑
      return Promise.reject({
        code: code,
        message: message
      })
    }
  },
  error => {
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      Message.error({
        message: '请求超时',
        type: 'error'
      })
      return
    }

    if (!error.response?.status) return
    if (error.response.status == 401) {
      Message({
        message: '登录失效，请重新进入',
        type: 'error',
        duration: 5 * 1000
      })
      toLogin()
      return
    } else if (error.response?.status >= 500) {
      Message({
        message: '请求失败，可能是网络问题引起的，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      Message({
        message: '请求失败，可能是网络问题引起的，请稍后重试',
        type: 'error',
        duration: 5 * 1000
      })
    }
    // 返回的err只是字符串 没有返回的必要 反而影响rea.message 所以直接reject 没有返回error
    return Promise.reject()
  }
)

export default service

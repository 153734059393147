import Layout from '@/layout'

const PaymentDetail = resolve => require(['@/views/accountDetail/PaymentDetail'], resolve)
const AccountLiveTransfer = resolve => require(['@/views/account/AccountLiveTransfer'], resolve)
const AccountUserTransfer = resolve => require(['@/views/account/AccountUserTransfer'], resolve)
const AccountUserBusiness = resolve => require(['@/views/account/AccountUserBusiness'], resolve)
const LiveAudit = resolve => require(['@/views/liveAudit/LiveAudit'], resolve)
const AccountInviteCode = resolve => require(['@/views/accountDetail/InviteCode'], resolve)
const AccountRecallCode = resolve => require(['@/views/accountDetail/RecallCode'], resolve)
const WithDrawTrain = resolve => require(['@/views/accountDetail/WithDrawTrain'], resolve)

export default {
  path: '/account',
  component: Layout,
  redirect: '/account',
  meta: {
    title: '财务管理',
    icon: 'account',
    auth: ['userNav']
  },
  children: [
    {
      path: '',
      name: 'Account',
      component: () => import(/* webpackChunkName: "Account" */'@/views/account/AccountLayout'),
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '财务管理',
        breadcrumb: false,
        activeMenu: '/account',
        cache: ['AccountInviteCode', 'AccountUserBusiness', 'PaymentDetail', 'AccountLiveTransfer', 'AccountUserTransfer']
      }
    },
    {
      path: 'user/business',
      name: 'AccountUserBusiness',
      component: AccountUserBusiness,
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '积分账户 - 业务明细',
        activeMenu: '/account'
      }
    },
    {
      path: 'live/business',
      name: 'AccountLiveBusiness',
      component: () => import(/* webpackChunkName: "Account" */'@/views/account/AccountLiveBusiness'),
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '视频采购账户 - 业务明细',
        activeMenu: '/account'
      }
    },
    {
      path: 'user/transfer',
      name: 'AccountUserTransfer',
      component: AccountUserTransfer,
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '积分账户 - 转账',
        activeMenu: '/account'
      }
    },
    {
      path: 'live/transfer',
      name: 'AccountLiveTransfer',
      component: AccountLiveTransfer,
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '视频采购账户 - 转账',
        activeMenu: '/account'
      }
    },
    // ------------------ 直播审核 --------------
    {
      path: 'live/audit',
      name: 'LiveAudit',
      component: LiveAudit,
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '财务',
        activeMenu: '/account'
      }
    },
    {
      path: 'payment/detail/:type/:id', // type string:账户类型； gredit->积分账户；live->直播账户；id string: 组织id
      name: 'PaymentDetail',
      component: PaymentDetail,
      meta: {
        requiresAuth: true,
        sidebar: false,
        title: '财务',
        activeMenu: '/account'
      }
    },
    {
      path: '/account/invitecode', // type string:任务类型； meeting -> 科会；questionnaire-> 调研；live -> 直播；
      name: 'AccountInviteCode',
      component: AccountInviteCode,
      meta: {
        requiresAuth: true,
        title: '积分账户 - 任务明细',
        sidebar: false,
        activeMenu: '/account'
      }
    },
    {
      path: '/account/recallcode', // type string:任务类型； meeting->科会；questionnaire->调研；live->直播；
      name: 'AccountRecallCode',
      component: AccountRecallCode,
      meta: {
        requiresAuth: true,
        title: '撤回任务',
        sidebar: false,
        activeMenu: '/account'
      }
    },
    {
      path: '/account/withDraw/train',
      name: 'WithDrawTrain',
      component: WithDrawTrain,
      meta: {
        requiresAuth: true,
        title: '撤回任务',
        sidebar: false,
        activeMenu: '/account'
      }
    }]
}

import actions from './action'
import { cloneDeep } from 'lodash-es'
import Cookies from 'js-cookie'
import { clearPersonInfo } from '@/utils/auth'
import * as Types from '../../mutations-types'
import { parseUid, setUid } from '@/utils/ali-log'
import microApp from '@micro-zoe/micro-app'
import { argolog } from '@/utils/argo-log'

const initialState = {
  wxCodeImage: {}, // 获取登录扫码的二维码链接和循环标识
  userInfo: JSON.parse(Cookies.get('_personInfo') || '{}').orgLoginVo || {}, // 用户信息
  metadataUserInfo: JSON.parse(Cookies.get('_personInfo') || '{}') || {}, // userInfo 准备废弃，请使用 metadataUserInfo
  permission: [], // 用户权限
  factoryAccountList: [], // 切换厂家账号
  paymentCodeImage: {}, // 支付扫码的二维码
  captchaAppId: '2065962030', // 腾讯云验证码appid
  accountCenterInfo: {
    accountInfoVO: {}
  }, // 安全中心个人信息
  accountLoginRecord: [], // 安全中心登录记录
  accountSensitiveRecord: [], // 安全中心敏感记录
  permissionRoles: [], // 用户权限列表
  loginTypeData: {
    0: '账号登录',
    1: '验证码登录',
    2: '微信扫码登录',
    3: '选择子账号登录'
  }, // 登录方式数据
  speakerExe: {} // 播客端下载信息
}

const state = cloneDeep(initialState)
const getters = {
  wxCodeImage: () => state.wxCodeImage,
  userInfo: () => state.userInfo,
  permission: () => state.permission,
  factoryAccountList: () => state.factoryAccountList,
  paymentCodeImage: () => state.paymentCodeImage,
  accountCenterInfo: () => state.accountCenterInfo,
  accountLoginRecord: () => state.accountLoginRecord,
  accountSensitiveRecord: () => state.accountSensitiveRecord,
  captchaAppId: () => state.captchaAppId,
  permissionRoles: () => state.permissionRoles,
  loginTypeData: () => state.loginTypeData,
  speakerExe: () => state.speakerExe
}

const mutations = {
  [Types.GET_WX_CODE_IMAGE](state, res) {
    state.wxCodeImage = res
  },
  [Types.SET_USER_INFO](state, res) {
    state.userInfo = res

    setUid(parseUid(res.token))
    microApp.setData('v3', res)
    const { name, token, topOrgId, orgId, topOrgName, orgName } = res.orgLoginVo || {}
    if (name) {
      argolog.setProfile({
        org_user_name: name,
        org_user_id: token.split('_')[2],
        top_org_id: topOrgId,
        top_org_name: topOrgName,
        current_org_id: orgId,
        current_org_name: orgName
      })
    }
  },
  [Types.SET_METADATA_USER_INFO](state, res) {
    state.metadataUserInfo = res
  },
  [Types.SET_USER_PERMISSION](state, res) {
    state.permission = res
  },
  [Types.SET_FACTORY_ACCOUNT_LIST](state, res) {
    state.factoryAccountList = res
  },
  [Types.ON_CHECK_FACTORY_ACCOUNT](state, item) {
    const newList = [...state.factoryAccountList].map(k => {
      if (k.id == item.id) {
        k.current = true
      } else {
        k.current = false
      }
      return k
    })
    state.factoryAccountList = newList
  },
  [Types.CHECK_PAYMENT_CODE_IMAGE](state, res) {
    state.paymentCodeImage = res
  },
  [Types.SET_ACCOUNT_INFO](state, res) {
    state.accountCenterInfo = res
  },
  [Types.SET_LOGIN_RECORD](state, res) {
    state.accountLoginRecord = res
  },
  [Types.SET_SENSITIVE_RECORD](state, res) {
    state.accountSensitiveRecord = res
  },
  [Types.SET_PERMISSION_ROLES](state, res) {
    state.permissionRoles = res
  },
  [Types.REMOVE_USER_DATA](state, place) {
    clearPersonInfo(place)
    state = cloneDeep(initialState)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}

import request from '@/utils/request'
import API from '@/apis/api-types'

export function getCurrentLoginInfo() {
  return request({
    method: 'get',
    url: API.getCurrentLoginInfo
  })
}
export function getOrgList() {
  return request({
    method: 'get',
    url: API.getOrgList
  })
}
export function getOrgManageList(req) {
  return request({
    method: 'get',
    url: API.getOrgManageList,
    params: req
  })
}
export function getTopOrgTree() {
  return request({
    method: 'get',
    url: API.getTopOrgTree
  })
}
export function getRepresentList(req) {
  return request({
    method: 'get',
    url: API.getRepresentList,
    params: req
  })
}

export function getTeamList(options) {     // 团队列表
  return request({
    url: API.getTeamList,
    method: 'get',
    params: options
  })
}

export function getDepartmentLayerDetail(data) {
  return request({
    method: 'get',
    url: API.getDepartmentLayerDetail,
    params: data
  })
}

export function onCreateDepartment(data) {
  return request({
    method: 'post',
    url: API.onCreateDepartment,
    data
  })
}

// -------------------------------- 组织管理重构 --------------------------

export function conditionTreeList(data) {
  return request({
    method: 'get',
    url: API.conditionTreeList,
    params: data
  })
}

export function getRepresentInfo(data) {
  return request({
    method: 'get',
    url: API.getRepresentInfo,
    params: data
  })
}

export function getRepresentDetailInfo(data) {
  return request({
    method: 'get',
    url: API.getRepresentDetailInfo,
    params: data
  })
}

export function bindRepresentList(data) {
  return request({
    method: 'get',
    url: API.bindRepresentList,
    params: data
  })
}

export function batchRecallCode(data) {
  return request({
    method: 'post',
    url: API.batchRecallCode,
    data: data
  })
}

export function batchImportOrgModel(data) {
  return request({
    method: 'post',
    url: API.batchImportOrgModel,
    data: data
  })
}

export function uploadBatchImportOrg(data) {
  return request({
    method: 'post',
    url: API.uploadBatchImportOrg,
    data: data
  })
}

export function uploadBatchImportOrgConfirm(file) {
  const form = new FormData()
  form.append('file', file)
  return request({
    method: 'post',
    url: API.uploadBatchImportOrgConfirm,
    data: form
  })
}

export function batchImportRepresentModel(data) {
  return request({
    method: 'post',
    url: API.batchImportRepresentModel,
    data: data
  })
}

export function uploadBatchImportRepresent(data) {
  return request({
    method: 'post',
    url: API.uploadBatchImportRepresent,
    data: data
  })
}

export function uploadBatchImportRepresentConfirm(file) {
  const form = new FormData()
  form.append('file', file)
  return request({
    method: 'post',
    url: API.uploadBatchImportRepresentConfirm,
    data: form
  })
}

export function getRepresentDoctors(data) {
  return request({
    method: 'get',
    url: API.getRepresentDoctors,
    params: data
  })
}

export function doctorChangeRepresent(data) {
  return request({
    method: 'post',
    url: API.doctorChangeRepresent,
    data: data
  })
}

export function changeOrganization(data) {
  return request({
    method: 'post',
    url: API.changeOrganization,
    data
  })
}
export function getBatchConditionTreeList(data) {
  return request({
    method: 'post',
    url: API.getBatchConditionTreeList,
    data
  })
}

export function getOrgInfoList(data) {
  return request({
    method: 'get',
    url: API.getOrgInfoList,
    params: data
  })
}

export function getOrgSearchList(data) {
  return request({
    method: 'get',
    url: API.getOrgSearchList,
    params: data
  })
}

export function getOrgOperate(data) {
  return request({
    method: 'get',
    url: API.getOrgOperate,
    params: data
  })
}

export function editOrgInfo(data) {
  return request({
    method: 'post',
    url: API.editOrgInfo,
    data: data
  })
}

export function onDelDepartment(data) {
  return request({
    method: 'post',
    url: API.onDelDepartment,
    params: data
  })
}

export function onMoveDepartment(data) {
  return request({
    method: 'post',
    url: API.onMoveDepartment,
    params: data
  })
}

export function frozenOrgInfo(data) {
  return request({
    method: 'post',
    url: API.frozenOrgInfo,
    params: data
  })
}

export function unFrozenOrgInfo(data) {
  return request({
    method: 'post',
    url: API.unFrozenOrgInfo,
    params: data
  })
}

export function uploadLogo(data) {
  return request({
    method: 'post',
    url: API.uploadLogo,
    data: data
  })
}

export function representSceneList(data) {
  return request({
    method: 'get',
    url: API.representSceneList,
    params: data
  })
}

export function factoryUsePer(data) {
  return request({
    method: 'post',
    url: API.factoryUsePer,
    data: data
  })
}

export function createOrModify(data) {
  return request({
    method: 'post',
    url: API.createOrModify,
    data: data
  })
}

export function removeUser(data) {
  return request({
    method: 'get',
    url: API.removeUser,
    params: data
  })
}

export function orgStaffList(data) {
  return request({
    method: 'get',
    url: API.orgStaffList,
    params: data
  })
}

export function factoryDirectRepresent(data) {
  return request({
    method: 'get',
    url: API.factoryDirectRepresent,
    params: data
  })
}

export function factoryAllRepresent(data) {
  return request({
    method: 'get',
    url: API.factoryAllRepresent,
    params: data
  })
}

export function needNoticeDelRepresent(data) {
  return request({
    method: 'get',
    url: API.needNoticeDelRepresent,
    params: data
  })
}

export function delOrgRepresent(data) {
  return request({
    method: 'get',
    url: API.delOrgRepresent,
    params: data
  })
}

export function recallResource(data) {
  return request({
    method: 'post',
    url: API.recallResource,
    data: data
  })
}
export function recallRepresentCode(data) {
  return request({
    method: 'post',
    url: API.recallRepresentCode,
    data: data
  })
}

export function freezeStaff(data) {
  return request({
    method: 'get',
    url: API.freezeStaff,
    params: data
  })
}

export function freezeMoreStaff(data) {
  return request({
    method: 'post',
    url: API.freezeMoreStaff,
    data: data
  })
}

export function representsDownload(data) {
  return request({
    method: 'get',
    url: API.representsDownload,
    params: data
  })
}

// export function screenRepresentList(data) {
//   return request({
//     method: 'get',
//     url: API.screenRepresentList,
//     params: data
//   })
// }

export function screenTrainRepresentList(data) {
  return request({
    method: 'get',
    url: API.screenTrainRepresentList,
    params: data
  })
}

export function createUpdateRepresent(data) {
  return request({
    method: 'post',
    url: API.createUpdateRepresent,
    data: data
  })
}

export function getAccounts(data) {
  return request({
    method: 'get',
    url: API.getAccounts,
    params: data
  })
}

export function downloadOrgList(data) {
  return request({
    method: 'get',
    url: API.downloadOrgList,
    params: data
  })
}

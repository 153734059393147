import * as UserService from '@/services/userService'
import * as Types from '../../mutations-types'
import { setUid } from '@/utils/ali-log'
export default {
  getWxCodeImage({ commit }, params) {
    return UserService.getWxCodeImage(params).then(res => {
      commit(Types.GET_WX_CODE_IMAGE, res?.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  checkWxCodeResult(_, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  userLogin(_, params) {
    return UserService.userLogin(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  userMobileLogin(_, params) {
    return UserService.userMobileLogin(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getPermission({ commit }) {
    return UserService.getPermission().then(res => {
      const data = res?.data || []
      commit(Types.SET_USER_PERMISSION, data)
      return Promise.resolve(data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getSmsCode(_, params) {
    return UserService.getSmsCode(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getFactoryAccountList({ commit }, params) {
    return UserService.getFactoryAccountList(params).then(res => {
      commit(Types.SET_FACTORY_ACCOUNT_LIST, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  onCheckFactoryAccount({ commit }, item) {
    commit(Types.ON_CHECK_FACTORY_ACCOUNT, item)
  },
  setNormalAccount(_, params) {
    return UserService.setNormalAccount(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getPaymentCodeImage({ commit }, params) {
    return UserService.getWxCodeImage(params).then(res => {
      commit(Types.CHECK_PAYMENT_CODE_IMAGE, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      commit(Types.CHECK_PAYMENT_CODE_IMAGE, [])
      return Promise.reject(rea)
    })
  },
  checkPaymentCodeResult(_, params) {
    return UserService.checkWxCodeResult(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getAccountInfo({ commit }, params) {
    return UserService.getAccountInfo(params).then(res => {
      commit(Types.SET_ACCOUNT_INFO, res.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getAccountScore(_, params) {
    return UserService.getAccountScore(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getLoginRecord({ commit }, params) {
    return UserService.getLoginRecord(params).then(res => {
      commit(Types.SET_LOGIN_RECORD, res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getSensitiveRecord({ commit }, params) {
    return UserService.getSensitiveRecord(params).then(res => {
      commit(Types.SET_SENSITIVE_RECORD, res.data.data)
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  getAccountsRoles({ commit }) {
    return UserService.getAccountsRoles().then(res => {
      res.data = res.data.map(item => {
        return {
          id: item.roleId,
          name: item.roleName
        }
      })
      commit(Types.SET_PERMISSION_ROLES, res.data)
    })
  },
  bindMobile(_, params) {
    return UserService.bindMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  changeMobile(_, params) {
    return UserService.changeMobile(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  changePwd(_, params) {
    return UserService.changePwd(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  setNewPassword(_, params) {
    return UserService.setNewPassword(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  paymentCheckValue(_, params) {
    return UserService.paymentCheckValue(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  paymentLimit(_, params) {
    return UserService.paymentLimit(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  checkCaptcha(_, params) {
    return UserService.checkCaptcha(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  bindWechat(_, params) {
    return UserService.bindWechat(params).then(res => {
      return Promise.resolve(res.data)
    }, rea => {
      return Promise.reject(rea)
    })
  },
  logout({ commit }, place) {
    commit('REMOVE_USER_DATA', place)
    commit('menu/invalidRoutes', null, { root: true })
    setUid('')
  }
}

import Layout from '@/layout'
import MicroApp from '@/layout/views/micro-app.vue'

export default {
  path: '/download',
  component: Layout,
  redirect: '/sub-app/download/center',
  meta: {
    title: '下载中心',
    icon: 'download'
  },
  children: [
    // {
    //   path: 'center',
    //   name: 'DownloadCenter',
    //   component: () => import(/* webpackChunkName: "download" */ '@/views/download/DownloadCenter'),
    //   meta: {
    //     title: '下载中心',
    //     sidebar: false,
    //     breadcrumb: false,
    //     activeMenu: '/download'
    //   }
    // },
    {
      path: '/sub-app/download/center',
      name: 'DownloadCenter',
      component: MicroApp,
      meta: {
        icon: 'download',
        title: '下载中心',
        sidebar: false,
        activeMenu: '/download'
      }
    }
  ]
}

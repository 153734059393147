import request from '@/utils/request'
import API from '@/apis/api-types'
export function getAcademicSceneList(req) {
  return request({
    url: API.getAcademicSceneList,
    method: 'post',
    data: req
  })
}
export function getJsonRepresentList(options) {  // 获取代表身份
  return request({
    url: API.getJsonRepresentList,
    method: 'post',
    data: options
  })
}
export function getAcademicSceneCodeList(options) {
  // 撤回任务，任务面额list
  return request({
    url: API.sceneCodeOverview,
    method: 'get',
    params: options
  })
}
export function getSingleSceneInput(options) {
  return request({
    url: API.getSingleSceneInput,
    method: 'get',
    params: options
  })
}
export function getDownOrgList(options) {
  return request({
    url: API.getDownOrgList,
    method: 'post',
    data: options
  })
}
export function getSceneCommentList({pageSize = 10, pageNo = 1, sceneId = '', sceneType}) {
  return request({
    url: API.getSceneCommentList,
    method: 'get',
    params: {pageSize, pageNo, sceneId, sceneType}
  })
}
export function getSceneReplyList(parentCommentId = '', pageNo = 1, pageSize = 100) {
  return request({
    url: API.getSceneReplyList,
    method: 'get',
    params: {pageSize, pageNo, parentCommentId}
  })
}
export function onShieldComment({commentId = '', isOpen = false, sceneId = '', sceneType}) {
  return request({
    url: API.onShieldComment,
    method: 'post',
    params: {commentId, isOpen, sceneId, sceneType}
  })
}
export function onSetTopComment({commentId = '', isTop = false, sceneId = '', sceneType}) {
  return request({
    url: API.onSetTopComment,
    method: 'post',
    params: {commentId, isTop, sceneId, sceneType}
  })
}
export function onSetActiveComment({commentId = '', isSelective = false, sceneId = '', sceneType}) {
  return request({
    url: API.onSetActiveComment,
    method: 'post',
    params: {commentId, isSelective, sceneId, sceneType}
  })
}
export function setCommentShowType({commentSettingId = '', ruleType = 1, sceneId = '', sceneType}) {
  return request({
    url: API.setCommentShowType,
    method: 'post',
    params: {commentSettingId, ruleType, sceneId, sceneType}
  })
}
export function onReplyComment({parentCommentId = '', content = '', sceneId = '', sceneType = 1}) {
  return request({
    url: API.onReplyComment,
    method: 'post',
    params: {parentCommentId, content, sceneId, sceneType}
  })
}
export function getSceneTemplateList({sceneId = '', sceneType = 4}) {
  return request({
    url: API.getSceneTemplateList,
    method: 'get',
    params: {sceneId, sceneType}
  })
}
export function onAllotSceneCode(opt) {
  return request({
    url: API.onAllotWithCode,
    method: 'post',
    data: opt.body,
    params: {checkValue: opt.ticket}
  })
}
export function getSceneRuleHistoryList(sceneType = '') {
  // 任务分配规则模板
  return request({
    url: API.getSceneRuleHistoryList,
    method: 'get',
    params: {sceneType}
  })
}
export function onCreateSceneRule(req) {
  return request({
    url: API.onCreateSceneRule,
    method: 'post',
    data: req
  })
}
export function onDelSceneRule(req) {
  return request({
    url: API.onDelSceneRule,
    method: 'post',
    params: req
  })
}
export function getAllotRuleDetail(req) {
  return request({
    url: API.getAllotRuleDetail,
    method: 'get',
    params: req
  })
}
export function getSceneDetailTag(req) {
  return request({
    url: API.getProjectTag,
    method: 'post',
    data: req
  })
}
export function onBindSceneTag(req) {
  return request({
    url: API.onBindSceneTag,
    method: 'post',
    data: req
  })
}
export function getMeetingDetail(meetingId = '') {
  return request({
    url: API.getMeetingDraftView,
    method: 'get',
    params: {meetingId}
  })
}
export function createMeeting(req) {
  return request({
    url: API.createMeeting,
    method: 'post',
    data: req
  })
}
export function editMeeting(req) {
  const url = req.isAttribute ? API.updateMeetingNormal : API.updateMeeting
  return request({
    url: url,
    method: 'post',
    data: req
  })
}
export function deleteMeeting(meetingId = '') {
  return request({
    url: API.onDelMeeting,
    method: 'post',
    params: {meetingId}
  })
}
export function createLive(req) {
  return request({
    url: API.createLive,
    method: 'post',
    data: req
  })
}
export function getLiveDetail(liveId) {
  return request({
    url: API.getLiveDetail,
    method: 'get',
    params: {liveId}
  })
}
export function onDelLive(liveId) {
  return request({
    url: API.onDelLive,
    method: 'get',
    params: {liveId}
  })
}
export function getLiveSummary(liveId) {
  return request({
    url: API.getLiveSummary,
    method: 'get',
    params: {liveId}
  })
}
export function getWebinarSpeakerList(liveId = '') {
  return request({
    url: API.getWebinarSpeakerList,
    method: 'get',
    params: {liveId}
  })
}
export function onCreateSceneExamJumpRule(req) {
  return request({
    url: API.onCreateSceneExamJumpRule,
    method: 'post',
    data: req
  })
}
export function onEditSceneExamJumpRule(req) {
  return request({
    url: API.onEditSceneExamJumpRule,
    method: 'post',
    data: req
  })
}
export function getSceneExamJumpRuleList(sceneId = '') {
  return request({
    url: API.getSceneExamJumpRuleList,
    method: 'get',
    params: {sceneId}
  })
}
export function onValidExamJumpLogic(req) {
  return request({
    url: API.onValidExamJumpLogic,
    method: 'get',
    params: req
  })
}
export function onDelSceneExamJumpRule(req) {
  return request({
    url: API.onDelSceneExamJumpRule,
    method: 'post',
    params: req
  })
}
export function getSimilarExamList({arr, searchType}) {
  return request({
    url: API.getSimilarExamList,
    method: 'post',
    params: {searchType},
    data: arr
  })
}
export function getQuestionnaireJumpTree(sceneId = '') {
  return request({
    url: API.getQuestionnaireJumpTree,
    method: 'get',
    params: {sceneId}
  })
}
export function onDelQuestionnaire(questionnaireId) {
  return request({
    url: API.delFactoryQuestionnaireDraft,
    method: 'post',
    params: {questionnaireId}
  })
}
export function getQuestionnaireDetail(questionnaireId) {
  return request({
    url: API.getQuestionnaireDraftDetail,
    method: 'get',
    params: {questionnaireId}
  })
}
// questionnaire
export function onEditQuestionnaire(req) {
  const url  = req.isAttribute ? API.updateQuestionnaireNormal : API.updateQuestionnaire
  return request({
    url: url,
    method: 'post',
    data: req
  })
}
export function onCreateQuestionnaire(req) {
  return request({
    url: API.createQuestionnaire,
    method: 'post',
    data: req
  })
}
export function getSceneDepList(options) {     // 厂家所辖部门和模板是否分配选中列表
  return request({
    url: API.getSceneDepList,
    method: 'post',
    data: options
  })
}
export function getOrganizationSiblingList(options) {
  // 获取同级组织
  return request({
    url: API.getOrganizationSiblingList,
    method: 'get',
    params: options
  })
}
export function getFactoryOrgUserList(opt) {
  return request({
    url: API.getFactoryOrgUserList,
    method: 'post',
    data: opt
  })
}

export function onEnsetRepDoctorList(req) {
  return request({
    url: API.onEnsetRepDoctorList,
    method: 'post',
    data: req
  })
}
export function getFactoryAllotCode(options) {  // 获取厂家端拥有任务 =>meeting or questionnaire
  return request({
    url: API.getFactoryAllotCode,
    method: 'get',
    params: options
  })
}
export function getSceneCodeUsedStatistic(sceneId = '') {
  return request({
    url: API.getSceneCodeUsedStatistic,
    method: 'get',
    params: {sceneId}
  })
}
export function onDownSceneAllotRecordData(opt = {}) {
  return request({
    url: API.onDownSceneAllotRecordData,
    method: 'get',
    params: opt
  })
}
export function getSceneAllotRecordList(sceneId = '') {
  return request({
    url: API.getSceneAllotRecordList,
    method: 'get',
    params: {sceneId}
  })
}
export function onRecallSceneRepCode(req) {
  return request({
    url: API.onRecallSceneRepCode,
    method: 'post',
    data: req
  })
}
export function onRecallSceneDepCode(req) {
  return request({
    url: API.onRecallSceneDepCode,
    method: 'post',
    data: req
  })
}
// 分配模版
export function sceneShareToDep({ sceneIds = [], factoryIds = [], sceneType = 'meeting', isSelectedPubResLib = false } = {}) {
  return request({
    url: API.sceneShare,
    method: 'post',
    data: {
      sceneIds,
      factoryIds,
      sceneType,
      isSelectedPubResLib
    }
  })
}
// 拜访
export function onDelAcademicVisit(visitId = '') {
  return request({
    url: API.delAcademicVisit,
    method: 'post',
    params: {
      visitId
    }
  })
}

export function onEditAcademicVisit(req) {
  const url = req.isAttribute ? API.academicVisitEditNormal : API.academicVisitEdit
  return request({
    url: url,
    method: 'post',
    data: req
  })
}
export function onCreateAcademicVisit(req) {
  return request({
    url: API.academicVisitCreate,
    method: 'post',
    data: req
  })
}
export function getAcademicVisitDetail(visitId = '') {
  return request({
    url: API.getAcademicVisitDetail,
    method: 'get',
    params: {
      visitId
    }
  })
}
